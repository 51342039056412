import React from "react"
import "./OrderedCard.scss"
import OrderedCardContent from "./OrderedCardContent"

const OrderedCard = prop => {
    return (
        prop.item.map((item, index) => {
            if(index == (prop.item.length - 1)) {
                return (
                    <div className="col-lg-3 col-md-6 col-12 mb-5 white">
                        <div className="last-item ordered-card">
                            <OrderedCardContent item={item} index={index}/>
                        </div>
                    </div>
                )
            } else if (index == 0) {
                return (
                    <div className="col-lg-3 col-md-6 col-12 mb-5 white">
                        <div className="first-item ordered-card">
                            <OrderedCardContent item={item} index={index}/>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="col-lg-3 col-md-6 col-12 mb-5 white">
                        <div className=" ordered-card">
                            <OrderedCardContent item={item} index={index}/>
                        </div>
                    </div>
                )
            }
        })
    )
}
export default OrderedCard
