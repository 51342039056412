import React from "react"
import "./WhiteBox.scss"
import { Link } from "gatsby"

const WhiteBox = prop => {
    return (
        <div className="white-box">
            <p className="clearfix">
                <img className="img-fluid mb-4" alt={prop.item.title + "icon"} src={prop.item.icon}/>
            </p>
            <h3 className="h3-text --uppercase secondary-blue mb-3">
                {
                    prop.item.linkTo && prop.item.linkTo ? (<Link className="secondary-blue" to={prop.item.linkTo} dangerouslySetInnerHTML={{ __html: prop.item.title }}></Link>) : (<span dangerouslySetInnerHTML={{ __html: prop.item.title }}></span>)
                }
            </h3>
            {
                prop.item.content && (
                    prop.item.services ? (<p className="primary-blue mb-4">{prop.item.content}</p>) : (<p className="primary-blue">{prop.item.content}</p>)
                )
            }

            {
                prop.item.services && prop.item.services.map((text, i) => {
                    return (
                        <div className="white-box__list secondary-blue">{text}</div>
                    )
                })
            }

            {
                prop.item.hover && (
                    <div className="hover-box">
                        <ul className="mb-0">
                        {prop.item.hover.map((item, index) => {
                            return (<li className="text-white" key={index}>{item}</li>)
                        })}
                        </ul>
                    </div>
                )
            }
        </div>
    )
}
export default WhiteBox
