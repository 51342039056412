import React from "react";
import "./QuoteBlock.scss"
import { Height } from "@mui/icons-material";

const QuoteBlocks = prop => {
    return (
        <div className="quote-block secondary-blue">
            <i className="quote-block__icon">“</i>
            <p className="quote-block__content">{prop.item.content}</p>
            <div className="quote-block__quoter-wrap mt-5">
                <p className="quote-block__quoter text-left">
                    {prop.item.quoter}
                    <span className="quote-block__quoter-title d-block">{prop.item.quoterTitle}</span>
                </p>
                <p className="mt-4 text-right"><img className="img-fluid" alt={"Quote logo" + prop.item.quoter} src={prop.item.logo} style={{height:35}} /></p>
            </div>
        </div>
    )
}
export default QuoteBlocks
