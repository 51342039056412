import React from "react"
import "./OrderedCard.scss"

const OrderedCardContent = prop => {
    return (
        <>
            <div className="ordered-card__header">
                <i className="h3-text white ordered-card__index mb-5">{prop.index+1}</i>
                <p className="h3-text --uppercase white text-center">{prop.item.title}</p>
            </div>
            <div className="ordered-card__body">
                <p>{prop.item.content}</p>
            </div>
        </>
    )
}

export default OrderedCardContent
