import React from "react"
import "./BannerFullScreen.scss"
import ButtonContact from "./ButtonContact"
import { Height } from "@mui/icons-material"

const BannerFullScreen = prop => {
    return (
        <>
        {prop.item.layout === "2-columns" ?
            (
                <section className="banner-fullscreen" style={{ backgroundImage: `url(${prop.item.backgroundImage})`}}>
                    <div className="wrapper" style={{ position: "relative",height:"100%" }}>
                        <div className="row align-items-center banner-fullscreen__content --2-columns text-left">
                            <div className="col-md-7 col-12">
                                {prop.item.textColor ?
                                    (<h1 className="h1-text --uppercase light-yellow">{prop.item.title}</h1>)
                                    :
                                    (<h1 className="h1-text --uppercase">{prop.item.title}</h1>)
                                }
                                <p>{prop.item.content}</p>
                                <p className="mt-5">
                                    {
                                        prop.item.buttonText ?
                                        (<ButtonContact item={{customClass:"--bg-yellow", customText: prop.item.buttonText, linkTo: "#contact-form", showArrow: prop.item.showArrow}}/>)
                                        :
                                        (<ButtonContact item={{customClass:"--bg-yellow", customText:"Let's Talk", linkTo: "#contact-form", showArrow: prop.item.showArrow}}/>)
                                    }
                                </p>
                            </div>
                            <div className="col-5 d-none d-md-block">
                                <img className="img-fluid mb-4 pb-3" alt="Generative AI Consulting Services banner" src={prop.item.image}/>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="banner-fullscreen" style={{ backgroundImage: `url(${prop.item.backgroundImage})`}}>
                    <div className="banner-fullscreen__content">
                            {prop.item.textColor ?
                                (<h1 className="h1-text --uppercase light-yellow">{prop.item.title}</h1>)
                                :
                                (<h1 className="h1-text --uppercase">{prop.item.title}</h1>)
                            }
                        <p>{prop.item.content}</p>
                        <p className="mt-5">
                            <ButtonContact />
                        </p>
                    </div>
                </section>
            )
        }
        </>
    )
}
export default BannerFullScreen
